import React, { Suspense } from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import InputFields from './InputFields';
import { withTranslation } from 'react-i18next';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ValidSMSModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code1: "", 
            code2: "", 
            code3: "", 
            code4: "",
            isMobile: window.innerWidth <= 850,
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: this.state.isMobile ? '100vw' : '30%',
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 850 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile); 
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    handleConfirmCode = async () => {
        const codeFields = ['code1', 'code2', 'code3', 'code4'];
        const code = codeFields.map(key => this.state[key].trim()).join('');

        this.setState(codeFields.reduce((acc, field) => {
            acc[field] = "";
            return acc;
        }, {}))

        if(code.length === 4 && await this.props.myShopApiStore.verifyPhone(code)){
            if(this.props.action  === 'Registration'){
                this.props.closeModal(false);
                this.props.ifaceMngr.setActiveTopMenu("ShopMenu");
                return;
            }
            this.props.closeModal();
            this.props.nextStep();
            // ////--->>>> // makes delivery order!
            // else if(this.props.action === 'Delivery'){
            //     this.props.openMapModal();
            // }
            // ////--->>>>
        }
    }

    handleCodeChange = (e) => {
        const { name, value } = e.target;

        if(!value.match(/\D/)){
            this.setState({[name]: value.slice(-1)});
        }
    }

    handleNewCodeClick = async () => {
        const { setWrongSMSCode, registerUser } = this.props.myShopApiStore;
        await registerUser();
        this.setState({code1: "", code2: "", code3: "", code4: ""});
        setWrongSMSCode(false);
    }

    render() {
        const { modalIsOpen, closeModal } = this.props;
        const { isWrongSMSCode } = this.props.myShopApiStore;
        const { t } = this.props;
        
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen}>
                <div className={style.ModalContainer}>
                    <form className={style.InputsForm} onSubmit={e => e.preventDefault()}>
                        <div className={style.ModalTitle}>
                            {t('WeSentCodeToMobile')}
                            <br/>
                            {t('EnterCodeHere')}
                            {isWrongSMSCode && 
                                <div className={style.WrongCodeText}>
                                    {t('CodeIsWrong')}
                                </div>
                            }
                        </div>
                        <div className={style.ModalInputs}>
                            <div className={style.Inputs}>
                                <InputFields isMobile={this.state.isMobile} handleChange={this.handleCodeChange} c1={this.state.code1} c2={this.state.code2} c3={this.state.code3} c4={this.state.code4}/>
                            </div>
                            
                            <div className={style.NewCodeText}>
                            {isWrongSMSCode &&
                                <>
                                    <span>{t('GetNewCode')} </span>
                                    <span onClick={this.handleNewCodeClick}>{t('ClickHere')}</span>
                                </>
                            }
                            </div>
                        </div>
                        <div className={style.ModalBtns}>
                            <button type='button' onClick={closeModal}>{t('Cancelation')}</button>
                            <button type='submit' onClick={this.handleConfirmCode}>
                                {t('allow')}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(ValidSMSModal);